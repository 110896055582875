<template>
  <div class="about">
    <div class="container">
      <!-- タイトル -->
      <div class="container title-container">
        <div class="border title-border">
          <div class="h1 padding-title">
            <span class="text-title text-w3">簡易版キャリア納得度診断</span>
          </div>
          <div class="subtitle">
            <p>各要素について、現職においてどの程度満足しているか、</p>
            <p>それぞれ0~100%で満足度を教えてください。</p>
          </div>
        </div>
      </div>

      <div class="container body-container">
        <div class="border body-border">
          <div class="pos_ur">
            <img src="@/assets/upper_2x.png" />
          </div>
          <div class="pos_bl">
            <img src="@/assets/lower_2x.png" />
          </div>

          <div class="f-container1">
            <div class="f-item11">要素</div>
            <div class="f-item12">内容</div>
            <div class="f-item13">
              それぞれ0〜100%の数値で満足度を入力ください。
            </div>
          </div>

          <div class="f-container2">
            <div class="f-item21">理念/目的</div>
            <div class="f-item22">
              <p>・組織が掲げる社会的な意義やビジョンに共感する。</p>
              <p>・ビジョン実現に組織が向かっていることを実感する。</p>
              <p>・対峙しているマーケットには将来性があるとおもう。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider0
                  class="silder"
                  v-model="userdata.satisfy0"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.satisfy0 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">職種/仕事</div>
            <div class="f-item22">
              <p>・日々取り組む仕事は誰かの役に立てていると価値を感じる。</p>
              <p>・自分自身がありたい姿に近づけているという成長実感がある。</p>
              <p>・任された仕事は自分自身に適性があると思う。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider1
                  class="silder"
                  v-model="userdata.satisfy1"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.satisfy1 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">同僚/上司</div>
            <div class="f-item22">
              <p>・職場の仲間は一緒に働きたいと思う人達です。</p>
              <p>・こうなりたいと思うロールモデルがいる。</p>
              <p>・居心地の良い社風だと思う。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider2
                  class="silder"
                  v-model="userdata.satisfy2"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.satisfy2 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">条件/特権</div>
            <div class="f-item22">
              <p>・ワークライフバランスを保つことができる。</p>
              <p>・給与や福利厚生には満足できている。</p>
              <p>・評価制度には納得感がある。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider3
                  class="silder"
                  v-model="userdata.satisfy3"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.satisfy3 }}</div>
          </div>

          <div class="d-grid gap-2 col-4 mx-auto">
            <button
              class="btn shadow-sm btn-next btn-margin-next"
              @click="$router.push('Result')"
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Slider from "@vueform/slider";
import { computed, defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {
    Slider0: Slider,
    Slider1: Slider,
    Slider2: Slider,
    Slider3: Slider,
  },
  setup() {
    const store = useStore();
    return {
      userdata: computed(() => store.state.userdata),
    };
  },
});
</script>

<style scoped lang="scss">
p {
  line-height: 1rem;
  margin-bottom: 0.2rem;
}

.text-w3 {
  font-family: "Hiragino Sans W3";
}

.padding-title {
  padding-top: 50px;
}

.title-container {
  background-color: #ffffff;
  text-align: center;
}

.body-container {
}

.title-border {
  color: #dcdcdc;
}

.text-title {
  color: #2d699d;
  border-bottom: solid;
  border-color: #00a0e9;
  border-width: 6px;
  padding-bottom: 6px;
}

.subtitle {
  color: #000000;
  padding-top: 10px;
  padding-bottom: 40px;
}

.body-box {
  width: 100px;
  height: 100px;
  background-color: #00a0e9;
}

.body-border {
  position: relative;
  color: #707070;
  background-color: #f5f5f5;
}

.f-container1 {
  display: flex;
  flex-diretion: row;
  padding-top: 70px;
  padding-bottom: 15px;
  color: #000000;
}

.f-item11 {
  text-align: center;
  flex-basis: 20%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
}

.f-item12 {
  text-align: center;
  flex-basis: 35%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 1px;
  margin-right: 1px;
}

.f-item13 {
  text-align: center;
  flex-basis: 45%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 22px;
  padding-bottom: 18px;
  margin-right: 40px;
  font-size: 0.8rem;
  align-items: center;
}

.f-container2 {
  display: flex;
  flex-diretion: row;
  padding-bottom: 20px;
  color: #000000;
}

.f-item21 {
  flex-basis: 20%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-item22 {
  flex-basis: 35%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  font-size: 0.8rem;
}

.f-item23 {
  flex-basis: 35%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.f-item24 {
  flex-basis: 10%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-item31 {
  flex-basis: 30%;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
}

.f-item32 {
  flex-basis: 60%;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 15px;
}

.f-item33 {
  flex-basis: 10%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-margin-next {
  margin-top: 50px;
  margin-bottom: 100px;
}

.btn-next {
  background-color: #ffcc63;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.pos_ur {
  position: absolute;
  top: -1px;
  right: -1px;
}

.pos_bl {
  position: absolute;
  bottom: -1px;
  left: -1px;
}
</style>

<style lang="scss" scoped>
.silder {
  background-color: #facd89;
  --slider-connect-bg: #facd89;
  --slider-tooltip-bg: #facd89;
  --slider-handle-ring-color: #facd8930;
  width: 80%;
  margin: 25px auto auto auto;
}

input[type="range"]::range-progress {
  height: 20px;
  border-radius: 10px 0 0 10px;
  background-color: #facd89;
  border: 2px solid #facd89;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #facd89;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
