import { render } from "./P1.vue?vue&type=template&id=97df650e&scoped=true"
import script from "./P1.vue?vue&type=script&lang=ts"
export * from "./P1.vue?vue&type=script&lang=ts"

import "./P1.vue?vue&type=style&index=0&id=97df650e&scoped=true&lang=scss"
import "./P1.vue?vue&type=style&index=1&id=97df650e&lang=scss&scoped=true"
import "@vueform/slider/themes/default.css?vue&type=style&index=2&lang=css"
script.render = render
script.__scopeId = "data-v-97df650e"

export default script