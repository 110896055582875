<template>
  <div class="start">
    <div
      class="mx-auto text-center"
      style="width: 50%; margin-top: 50px; min-width: 1280px"
    >
      <!-- タイトル -->
      <div class="h1 title">
        <span class="text-title">簡易版キャリア納得度診断</span>
      </div>
    </div>

    <div class="mx-auto" style="width: 100%; margin-top: 75px">
      <table class="table mx-auto table-borderless" style="width: 1024px">
        <tbody>
          <tr>
            <td style="width: 1024px; height: 200px; position: relative">
              <p class="position-absolute text-center">
                ✓あなたのキャリアに対する納得度はどれぐらいでしょうか？<br>✓所要時間は2分～3分程度です。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table mx-auto table-borderless" style="width: 50%">
        <tbody>
          <tr>
            <td style="width: 50%">
              <div style="text-align: center"><p>氏名をご入力ください</p></div>
            </td>
            <td style="width: 50%">
              <div>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="userdata.name"
                    class="form-control center-text"
                    style="font-size: 2rem"
                    placeholder="テキスト入力"
                    @input="update()"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center">
      <button
        v-bind:disabled="isDisabled"
        class="btn btn_next btn-outline-dark"
        @click="$router.push('p1')"
      >
        診断スタート&nbsp;&nbsp;→
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {},
  setup() {
    // Storeを取得する
    var isDisabled = ref<boolean>(true);
    const store = useStore();
    const update = () => {
      if ("" == store.state.userdata.name.trim()) {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
    };

    return {
      update,
      isDisabled,
      userdata: computed(() => store.state.userdata),
    };
  },
});
</script>

<style scoped lang="scss">
.text-title {
  color: #2d699d;
  border-bottom: solid;
  border-color: #00a0e9;
  border-width: 6px;
  padding-bottom: 6px;
  font-family: "Hiragino Sans W3";
}

p {
  font-size: 2rem;
}

.position-absolute {
  position: absolute; /*要素を浮かす*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; /*上下左右中央に*/
  width: 1024px; /*widthの指定は必須*/
  height: 100px; /*heightの指定は必須*/
}

.btn_next {
  background-color: #ffcc63;
  margin-top: 2.0rem;
  padding: 1.0rem 2.0rem 1.0rem 2.0rem;
  text-align: center;
  font-size: 2rem;
}
</style>
