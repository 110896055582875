
import VueApexCharts from "vue3-apexcharts";
import ApiService from "../services/ApiService";
import { store } from "../store";

export default {
  components: {
    pie_chart: VueApexCharts,
    bar_chart: VueApexCharts,
  },
  data() {
    return {
      colorBG0: "#ffffff",
      colorBG1: "#ffffff",
      colorBG2: "#ffffff",
      colorBG3: "#ffffff",
      colorBG4: "#ffffff",
      pie: {
        options: {
          labels: ["会社", "仲間", "条件", "仕事"],
          title: {
            text: "重要度",
            align: "center",
          },
          colors: ["#50D6DE", "#FFCC63", "#F26D8A", "#9295F9"],
          legend: {
            show: true,
          },
          tooltip: {
            enabled: false,
          },
        },
        series: [
          store.state.userdata.weight0,
          store.state.userdata.weight1,
          store.state.userdata.weight2,
          store.state.userdata.weight3,
        ],
      },

      bar: {
        series: [
          {
            data: [
              store.state.userdata.satisfy0,
              store.state.userdata.satisfy1,
              store.state.userdata.satisfy2,
              store.state.userdata.satisfy3,
            ],
          },
        ],
        options: {
          title: {
            text: "満足度",
            align: "center",
          },
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          colors: ["#50D6DE", "#FFCC63", "#F26D8A", "#9295F9"],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
            },
          },
          tooltip: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            categories: ["会社軸", "仲間軸", "条件軸", "仕事軸"],
          },
          yaxis: {
            max: 100,
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
            position: "right",
            offsetX: 0,
            offsetY: 50,
          },
        },
      },
    };
  },
  mounted: async function() {
    let ret = await ApiService.put_result({
      "name": store.state.userdata.name,
      "weight0": store.state.userdata.weight0,
      "weight1": store.state.userdata.weight1,
      "weight2": store.state.userdata.weight2,
      "weight3": store.state.userdata.weight3,
      "satisfy0": store.state.userdata.satisfy0,
      "satisfy1": store.state.userdata.satisfy1,
      "satisfy2": store.state.userdata.satisfy2,
      "satisfy3": store.state.userdata.satisfy3
    });
    if ("convinced_rate" in ret.data) {
      store.state.userdata.score = ret.data.convinced_rate;
      if (store.state.userdata.score >= 81) {
        (this as any).colorBG0 = "#fff799";
      } else if (store.state.userdata.score >= 61) {
        (this as any).colorBG1 = "#fff799";
      } else if (store.state.userdata.score >= 41) {
        (this as any).colorBG2 = "#fff799";
      } else if (store.state.userdata.score >= 21) {
        (this as any).colorBG3 = "#fff799";
      } else {
        (this as any).colorBG4 = "#fff799";
      }
    }
  }
};
