
import Slider from "@vueform/slider";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {
    Slider0: Slider,
    Slider1: Slider,
    Slider2: Slider,
    Slider3: Slider,
  },
  setup() {
    var valuesum = ref<number>(100);
    var isDisabled = ref<boolean>(false);
    var SumColor = ref<string>("color:#000000;");
    const store = useStore();
    const update = (pos: number) => {
      var valuetmp =
        store.state.userdata.weight0 +
        store.state.userdata.weight1 +
        store.state.userdata.weight2 +
        store.state.userdata.weight3;
      valuesum.value = valuetmp;
      if (100 == valuetmp) {
        isDisabled.value = false;
        SumColor.value = "color:#000000;";
      } else {
        isDisabled.value = true;
        if (100 > valuetmp) {
          SumColor.value = "color:#ff0000;";
        } else {
          switch (pos) {
            case 0:
              //              store.state.userdata.weight0 =
              //                100 - valuetmp + store.state.userdata.weight0;
              break;
            case 1:
              //              store.state.userdata.weight1 =
              //                100 - valuetmp + store.state.userdata.weight1;
              break;
            case 2:
              //              store.state.userdata.weight2 =
              //                100 - valuetmp + store.state.userdata.weight2;
              break;
            case 3:
              //              store.state.userdata.weight3 =
              //                100 - valuetmp + store.state.userdata.weight3;
              break;
          }
          SumColor.value = "color:#ff0000;";
        }
      }
    };

    return {
      update,
      isDisabled,
      SumColor,
      valuesum,
      userdata: computed(() => store.state.userdata),
    };
  },
});
