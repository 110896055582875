
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {},
  setup() {
    // Storeを取得する
    var isDisabled = ref<boolean>(true);
    const store = useStore();
    const update = () => {
      if ("" == store.state.userdata.name.trim()) {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
    };

    return {
      update,
      isDisabled,
      userdata: computed(() => store.state.userdata),
    };
  },
});
