<template>
  <div class="about">
    <div class="container">
      <!-- タイトル -->
      <div class="container title-container">
        <div class="border title-border">
          <table class="mx-auto">
            <tr>
              <td>
                <div class="h1 padding-title">
                  <span class="text-title text-w3"
                    >簡易版キャリア納得度診断</span
                  >
                </div>
              </td>
              <td>
                <div class="box">
                  <img src="@/assets/baloon.svg" class="img_01" />
                  <img src="@/assets/result.svg" class="img_02" />
                </div>
              </td>
            </tr>
          </table>
          <div class="result">
            <span>納得度</span><span class="result-percent">&nbsp;{{$store.state.userdata.score}}%</span>
          </div>
        </div>
      </div>

      <div class="container body-container">
        <div class="border body-border">
          <div class="pos_ur">
            <img src="@/assets/upper_2x.png" />
          </div>
          <div class="pos_bl">
            <img src="@/assets/lower_2x.png" />
          </div>
          <div class="box"></div>

          <div class="f-container4" v-bind:style="{'background-color':colorBG0}">
            <div class="f-item41">81〜100％</div>
            <div class="f-item42">納得度高く、イキイキ！<br>&nbsp;</div>
          </div>
          <div class="line"></div>
          <div class="f-container4" v-bind:style="{'background-color':colorBG1}">
            <div class="f-item41">61〜80%</div>
            <div class="f-item42">
              部分的に納得できていない。<br />課題は明確になりましたか？
            </div>
          </div>
          <div class="line"></div>
          <div class="f-container4" v-bind:style="{'background-color':colorBG2}">
            <div class="f-item41">41〜60%</div>
            <div class="f-item42">
              複数項目で納得できておらず、強いモヤモヤ感。<br />現状を変えるアクションを起こしてみては？
            </div>
          </div>
          <div class="line"></div>
          <div class="f-container4" v-bind:style="{'background-color':colorBG3}">
            <div class="f-item41">21〜40%</div>
            <div class="f-item42">
              納得度がかなり低い状態。<br />現状を変えるアクションを起こしてみては？
            </div>
          </div>
          <div class="line"></div>
          <div class="f-container4" v-bind:style="{'background-color':colorBG4}">
            <div class="f-item41">20〜0%</div>
            <div class="f-item42">
              納得度が極めて低い状態。<br />環境を変えることを強くおすすめします。
            </div>
          </div>

          <div class="f-container5">
            <div class="f-item51">
              <pie_chart
                type="pie"
                height="400"
                :options="pie.options"
                :series="pie.series"
              ></pie_chart>
            </div>
            <div class="f-item52">
              <bar_chart
                type="bar"
                height="400"
                :options="bar.options"
                :series="bar.series"
              ></bar_chart>
            </div>
          </div>

          <div class="d-grid gap-2 col-4 mx-auto">
            <a
              class="btn shadow-sm btn-next btn-margin-next"
              href="https://career-partner.jp/trial/"
            >
              診断結果を保存して無料相談を行う
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import VueApexCharts from "vue3-apexcharts";
import ApiService from "../services/ApiService";
import { store } from "../store";

export default {
  components: {
    pie_chart: VueApexCharts,
    bar_chart: VueApexCharts,
  },
  data() {
    return {
      colorBG0: "#ffffff",
      colorBG1: "#ffffff",
      colorBG2: "#ffffff",
      colorBG3: "#ffffff",
      colorBG4: "#ffffff",
      pie: {
        options: {
          labels: ["会社", "仲間", "条件", "仕事"],
          title: {
            text: "重要度",
            align: "center",
          },
          colors: ["#50D6DE", "#FFCC63", "#F26D8A", "#9295F9"],
          legend: {
            show: true,
          },
          tooltip: {
            enabled: false,
          },
        },
        series: [
          store.state.userdata.weight0,
          store.state.userdata.weight1,
          store.state.userdata.weight2,
          store.state.userdata.weight3,
        ],
      },

      bar: {
        series: [
          {
            data: [
              store.state.userdata.satisfy0,
              store.state.userdata.satisfy1,
              store.state.userdata.satisfy2,
              store.state.userdata.satisfy3,
            ],
          },
        ],
        options: {
          title: {
            text: "満足度",
            align: "center",
          },
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          colors: ["#50D6DE", "#FFCC63", "#F26D8A", "#9295F9"],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
            },
          },
          tooltip: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            categories: ["会社軸", "仲間軸", "条件軸", "仕事軸"],
          },
          yaxis: {
            max: 100,
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
            position: "right",
            offsetX: 0,
            offsetY: 50,
          },
        },
      },
    };
  },
  mounted: async function() {
    let ret = await ApiService.put_result({
      "name": store.state.userdata.name,
      "weight0": store.state.userdata.weight0,
      "weight1": store.state.userdata.weight1,
      "weight2": store.state.userdata.weight2,
      "weight3": store.state.userdata.weight3,
      "satisfy0": store.state.userdata.satisfy0,
      "satisfy1": store.state.userdata.satisfy1,
      "satisfy2": store.state.userdata.satisfy2,
      "satisfy3": store.state.userdata.satisfy3
    });
    if ("convinced_rate" in ret.data) {
      store.state.userdata.score = ret.data.convinced_rate;
      if (store.state.userdata.score >= 81) {
        (this as any).colorBG0 = "#fff799";
      } else if (store.state.userdata.score >= 61) {
        (this as any).colorBG1 = "#fff799";
      } else if (store.state.userdata.score >= 41) {
        (this as any).colorBG2 = "#fff799";
      } else if (store.state.userdata.score >= 21) {
        (this as any).colorBG3 = "#fff799";
      } else {
        (this as any).colorBG4 = "#fff799";
      }
    }
  }
};
</script>

<style scoped lang="scss">
p {
  line-height: 1rem;
  margin-bottom: 0.2rem;
}

.text-w3 {
  font-family: "Hiragino Sans W3";
}

.result {
  background-color: #50d6de;
  color: #000000;
  font-size: 2.5rem;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 50px;
}

.result-percent {
  color: #fff799;
}

.padding-title {
  padding-top: 50px;
}

.title-container {
  background-color: #ffffff;
  text-align: center;
}

.body-container {
}

.title-border {
  color: #dcdcdc;
}

.text-title {
  color: #2d699d;
  border-bottom: solid;
  border-color: #00a0e9;
  border-width: 6px;
  padding-bottom: 6px;
}

.subtitle {
  color: #000000;
  padding-top: 10px;
  padding-bottom: 40px;
}

.body-box {
  width: 100px;
  height: 100px;
  background-color: #00a0e9;
}

.body-border {
  position: relative;
  color: #707070;
  background-color: #f5f5f5;
}

.box {
  height: 50px;
}

.line {
  display: flex;
  flex-diretion: row;
  margin-left: 230px;
  margin-right: 230px;
  height: 1px;
  background-color: #dae07d;
}

.f-container4 {
  display: flex;
  flex-diretion: row;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 200px;
  margin-right: 200px;
  color: #000000;
}

.f-item41 {
  text-align: center;
  flex-basis: 35%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.f-item42 {
  text-align: left;
  flex-basis: 65%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.f-container5 {
  display: flex;
  flex-diretion: row;
  padding-bottom: 20px;
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
}

.f-item51 {
  flex-basis: 50%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-item52 {
  flex-basis: 50%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-margin-next {
  margin-top: 50px;
  margin-bottom: 100px;
}

.btn-next {
  background-color: #ffcc63;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.pos_ur {
  position: absolute;
  top: -1px;
  right: -1px;
}

.pos_bl {
  position: absolute;
  bottom: -1px;
  left: -1px;
}

.box {
  position: relative;
  width: 100px;
  height: 100px;
}

.box .img_01 {
  position: absolute;
  top: 0;
  left: 0px;
}

.box .img_02 {
  position: absolute;
  top: 30px;
  left: 30px;
}
</style>
