import { render } from "./P2.vue?vue&type=template&id=1017455e&scoped=true"
import script from "./P2.vue?vue&type=script&lang=ts"
export * from "./P2.vue?vue&type=script&lang=ts"

import "./P2.vue?vue&type=style&index=0&id=1017455e&scoped=true&lang=scss"
import "./P2.vue?vue&type=style&index=1&id=1017455e&lang=scss&scoped=true"
import "@vueform/slider/themes/default.css?vue&type=style&index=2&lang=css"
script.render = render
script.__scopeId = "data-v-1017455e"

export default script