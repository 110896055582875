<template>
  <div class="about">
    <div class="container">
      <!-- タイトル -->
      <div class="container title-container">
        <div class="border title-border">
          <div class="h1 padding-title">
            <span class="text-title text-w3">簡易版キャリア納得度診断</span>
          </div>
          <div class="subtitle">
            <p>あなたの価値観を教えてください。</p>
            <p>
              働く理由を便宜的に以下4つの要素に分類した場合、それぞれの要素をどの程度重視しているか。
            </p>
          </div>
        </div>
      </div>

      <div class="container body-container">
        <div class="border body-border">
          <div class="pos_ur">
            <img src="@/assets/upper_2x.png" />
          </div>
          <div class="pos_bl">
            <img src="@/assets/lower_2x.png" />
          </div>

          <div class="f-container1">
            <div class="f-item11">要素</div>
            <div class="f-item12">内容</div>
            <div class="f-item13">
              以下4つの数値の合計が100になるように入力してください。
            </div>
          </div>

          <div class="f-container2">
            <div class="f-item21">理念/目的</div>
            <div class="f-item22">
              <p>・組織が掲げる社会的な意義やビジョンに共感する。</p>
              <p>・ビジョン実現に組織が向かっていることを実感する。</p>
              <p>・対峙しているマーケットには将来性があるとおもう。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider0
                  class="silder"
                  v-model="userdata.weight0"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                  @update="update(0)"
                  @change="update(0)"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.weight0 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">職種/仕事</div>
            <div class="f-item22">
              <p>・日々取り組む仕事は誰かの役に立てていると価値を感じる。</p>
              <p>・自分自身がありたい姿に近づけているという成長実感がある。</p>
              <p>・任された仕事は自分自身に適性があると思う。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider1
                  class="silder"
                  v-model="userdata.weight1"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                  @update="update(1)"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.weight1 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">同僚/上司</div>
            <div class="f-item22">
              <p>・職場の仲間は一緒に働きたいと思う人達です。</p>
              <p>・こうなりたいと思うロールモデルがいる。</p>
              <p>・居心地の良い社風だと思う。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider2
                  class="silder"
                  v-model="userdata.weight2"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                  @update="update(2)"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.weight2 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item21">条件/特権</div>
            <div class="f-item22">
              <p>・ワークライフバランスを保つことができる。</p>
              <p>・給与や福利厚生には満足できる。</p>
              <p>・評価制度には納得感がある。</p>
            </div>
            <div class="f-item23">
              <div>
                <Slider3
                  class="silder"
                  v-model="userdata.weight3"
                  :max="100"
                  :min="0"
                  :tooltips="false"
                  @update="update(3)"
                />
              </div>
            </div>
            <div class="f-item24">{{ userdata.weight3 }}</div>
          </div>

          <div class="f-container2">
            <div class="f-item31"></div>
            <div class="f-item32">
              <p>
                例）「誰と一緒に働くか」がとても重要で、それ以外の要素は一切問わない、という方は
              </p>
              <p>上から0、0、100、0という入力値となります。</p>
            </div>
            <div class="f-item33">
              <p>合計値:</p>
              <p v-bind:style="SumColor">{{ valuesum }}</p>
            </div>
          </div>

          <div class="d-grid gap-2 col-4 mx-auto">
            <button
              v-bind:disabled="isDisabled"
              class="btn shadow-sm btn-next btn-margin-next"
              @click="$router.push('p2')"
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Slider from "@vueform/slider";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {
    Slider0: Slider,
    Slider1: Slider,
    Slider2: Slider,
    Slider3: Slider,
  },
  setup() {
    var valuesum = ref<number>(100);
    var isDisabled = ref<boolean>(false);
    var SumColor = ref<string>("color:#000000;");
    const store = useStore();
    const update = (pos: number) => {
      var valuetmp =
        store.state.userdata.weight0 +
        store.state.userdata.weight1 +
        store.state.userdata.weight2 +
        store.state.userdata.weight3;
      valuesum.value = valuetmp;
      if (100 == valuetmp) {
        isDisabled.value = false;
        SumColor.value = "color:#000000;";
      } else {
        isDisabled.value = true;
        if (100 > valuetmp) {
          SumColor.value = "color:#ff0000;";
        } else {
          switch (pos) {
            case 0:
              //              store.state.userdata.weight0 =
              //                100 - valuetmp + store.state.userdata.weight0;
              break;
            case 1:
              //              store.state.userdata.weight1 =
              //                100 - valuetmp + store.state.userdata.weight1;
              break;
            case 2:
              //              store.state.userdata.weight2 =
              //                100 - valuetmp + store.state.userdata.weight2;
              break;
            case 3:
              //              store.state.userdata.weight3 =
              //                100 - valuetmp + store.state.userdata.weight3;
              break;
          }
          SumColor.value = "color:#ff0000;";
        }
      }
    };

    return {
      update,
      isDisabled,
      SumColor,
      valuesum,
      userdata: computed(() => store.state.userdata),
    };
  },
});
</script>

<style scoped lang="scss">
p {
  line-height: 1rem;
  margin-bottom: 0.2rem;
}

.text-w3 {
  font-family: "Hiragino Sans W3";
}

.padding-title {
  padding-top: 50px;
}

.title-container {
  background-color: #ffffff;
  text-align: center;
}

.body-container {
}

.title-border {
  color: #dcdcdc;
}

.text-title {
  color: #2d699d;
  border-bottom: solid;
  border-color: #00a0e9;
  border-width: 6px;
  padding-bottom: 6px;
}

.subtitle {
  color: #000000;
  padding-top: 10px;
  padding-bottom: 40px;
}

.body-box {
  width: 100px;
  height: 100px;
  background-color: #00a0e9;
}

.body-border {
  position: relative;
  color: #707070;
  background-color: #f5f5f5;
}

.f-container1 {
  display: flex;
  flex-diretion: row;
  padding-top: 70px;
  padding-bottom: 15px;
  color: #000000;
}

.f-item11 {
  text-align: center;
  flex-basis: 20%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
}

.f-item12 {
  text-align: center;
  flex-basis: 35%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 1px;
  margin-right: 1px;
}

.f-item13 {
  text-align: center;
  flex-basis: 45%;
  background-color: #ffffff;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  padding-top: 22px;
  padding-bottom: 18px;
  margin-right: 40px;
  font-size: 0.8rem;
  align-items: center;
}

.f-container2 {
  display: flex;
  flex-diretion: row;
  padding-bottom: 20px;
  color: #000000;
}

.f-item21 {
  flex-basis: 20%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-item22 {
  flex-basis: 35%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  font-size: 0.8rem;
}

.f-item23 {
  flex-basis: 35%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.f-item24 {
  flex-basis: 10%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-item31 {
  flex-basis: 30%;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  color: #000000;
}

.f-item32 {
  flex-basis: 60%;
  background-color: #fff2cc;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  border-color: #325490;
  border-style: solid;
  border-width: 1px;
  margin-right: 15px;
  text-align: center;
}

.f-item33 {
  flex-basis: 10%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-margin-next {
  margin-top: 50px;
  margin-bottom: 100px;
}

.btn-next {
  background-color: #ffcc63;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.pos_ur {
  position: absolute;
  top: -1px;
  right: -1px;
}

.pos_bl {
  position: absolute;
  bottom: -1px;
  left: -1px;
}
</style>

<style lang="scss" scoped>
.silder {
  background-color: #facd89;
  --slider-connect-bg: #facd89;
  --slider-tooltip-bg: #facd89;
  --slider-handle-ring-color: #facd8930;
  width: 80%;
  margin: 25px auto auto auto;
}

input[type="range"]::range-progress {
  height: 20px;
  border-radius: 10px 0 0 10px;
  background-color: #facd89;
  border: 2px solid #facd89;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #facd89;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
