
import Slider from "@vueform/slider";
import { computed, defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  components: {
    Slider0: Slider,
    Slider1: Slider,
    Slider2: Slider,
    Slider3: Slider,
  },
  setup() {
    const store = useStore();
    return {
      userdata: computed(() => store.state.userdata),
    };
  },
});
